.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  gap: 6px;
  width: auto;
  height: 6px;
  top: 8px;
  right: 8px;
  left: auto;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  margin: 0;
  border-radius: 6px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.3s linear;
}
.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
}

/* bullet-tr */
.bullet-tr.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: flex;
  gap: 6px;
  width: auto;
  height: 6px;
  top: 8px;
  right: 8px;
  left: auto;
}
.bullet-tr.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  margin: 0;
  border-radius: 6px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.3s linear;
}
.bullet-tr.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
}

/* bullet-bl */
.bullet-bl.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-horizontal {
  top: auto;
  right: auto;
  bottom: 30px;
  left: 32px;
}
.bullet-bl.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0.2);
}
.bullet-bl.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 20px;
  background-color: black;
}

/* bullet-bt */
.bullet-bt.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-horizontal {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.bullet-bt.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0.2);
}
.bullet-bt.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 20px;
  background-color: black;
}

/* bullet background */
.bullet-bg.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-horizontal {
  top: auto;
  right: auto;
  bottom: 10px;
  right: 9px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 5px;
  height: 15px;
  background-color: #00000050;
}

/* white-banner */
.white-banner.swiper-horizontal
  > .swiper-pagination-bullets.swiper-pagination-horizontal {
  position: initial;
  justify-content: center;
  margin-top: 24px;
}
.white-banner.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  background-color: rgba(0, 0, 0, 0.2);
}
.white-banner.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 20px;
  background-color: black;
}

.singleImgSwiper {
  position: relative !important;
}
.singleImgSwiper .swiper-wrapper {
  height: 95%;
}
.singleImgSwiper .swiper-wrapper .swiper-slide {
  overflow: hidden;
  touch-action: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.singleImgSwiper .swiper-wrapper .swiper-slide > div {
  width: 100%;
  height: 100%;
}
.singleImgSwiper .swiper-wrapper .swiper-slide > div > div {
  /* width: auto;
  height: auto; */
}
.singleImgSwiper .swiper-pagination {
  position: absolute;
  top: auto !important;
  bottom: 3% !important;
  left: 0px !important;
  right: 0px !important;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
